import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    image: {
        maxHeight: '200px',
        maxWidth: '200px',
    },
    placeholder: {
        position: 'relative',
        'z-index': '666',
    },
    '@media (min-width: 1024px) ': {
        placeholder: {
            position: 'relative',
            'z-index': '666',
            top: '-40%',
            left: '-40%',
        },
    },
});
export default useStyles;
